@tailwind base;
@tailwind components;
@tailwind utilities;

.green-theme {
    --theme-main: #36998e;
    --theme-main100: #40b6a8;
    --next-step: #91ddab;
    --second-color: #f9f871;
    --button-text: #484848;
    --button-hover: #fbfa9c;
    --button-shadow: rgba(98, 97, 4, 0.4);
    --button-hover-shadow: rgba(98, 97, 4, 0.4);
    --bg-icon: #fdfbba;
    --bg-color: #f5faf9;
}

.red-theme {
    --theme-main: #202939;
    --theme-main100: #e74860;
    --next-step: #fdedf0;
    --second-color: #e74860;
    --button-text: #ffffff;
    --button-hover: #5d4e1e;
    --button-shadow: #992600;
    --button-hover-shadow: #746125;
    --bg-icon: #fdedf0;
    --bg-color: #eef2f6;
}

.ca-theme {
    --theme-main: #695230;
    --theme-main100: #e81742;
    --next-step: #fdedf0;
    --second-color: #e81742;
    --button-text: #ffffff;
    --button-hover: #cc143a;
    --button-shadow: #a3102e;
    --button-hover-shadow: #8b0e28;
    --bg-icon: #f5ede6;
    --bg-color: #fbf7f4;
}

.gold-theme {
    --theme-main: #90792e;
    --theme-main100: #cc3300;
    --next-step: #fff9f2;
    --second-color: #cc3300;
    --button-shadow: #992600;
    --button-text: #ffffff;
    --button-hover: #5d4e1e;
    --button-hover-shadow: #746125;
    --bg-icon: rgba(144, 121, 46, 0.2);
    --bg-color: #fff9f2;
}

.oh-theme {
    --theme-main: #004d3d;
    --theme-main100: #fc5300;
    --next-step: #fff9f2;
    --second-color: #fc5300;
    --button-shadow: #cc4300;
    --button-text: #ffffff;
    --button-hover: #ff7633;
    --button-hover-shadow: #cc4300;
    --bg-icon: #e1eae8;
    --bg-color: #f2f6f5;
}

.fl-theme {
    --theme-main: #206db0;
    --theme-main100: #fdf34c;
    --next-step: #fff9f2;
    --second-color: #fdf34c;
    --button-shadow: #cabe02;
    --button-text: #484848;
    --button-hover: #fef781;
    --button-hover-shadow: #cabe02;
    --bg-icon: #e0eefa;
    --bg-color: #f2f8fd;
}

.ny-theme {
    --theme-main: #003884;
    --theme-main100: #ff6600;
    --next-step: #fff9f2;
    --second-color: #ff6600;
    --button-shadow: #cc4300;
    --button-text: #ffffff;
    --button-hover: #ff781f;
    --button-hover-shadow: #cc4300;
    --bg-icon: #3387ff;
    --bg-color: #ebf3ff;
}

.tx-theme {
    --theme-main: #003884;
    --theme-main100: #b22234;
    --next-step: #fff9f2;
    --second-color: #b22234;
    --button-shadow: #891a28;
    --button-text: #ffffff;
    --button-hover: #d83146;
    --button-hover-shadow: #891a28;
    --bg-icon: #3387ff;
    --bg-color: #ebf3ff;
}

.ga-theme {
    --theme-main: #000000;
    --theme-main100: #ba0c2f;
    --next-step: #fff9f2;
    --second-color: #ba0c2f;
    --button-shadow: #8b0923;
    --button-text: #ffffff;
    --button-hover: #eb0f3b;
    --button-hover-shadow: #8b0923;
    --bg-icon: #f8e7ea;
    --bg-color: #d6d2c4;
}

.nc-theme {
    --theme-main: #7bafd4;
    --theme-main100: #cc3300;
    --next-step: #fff9f2;
    --second-color: #cc3300;
    --button-shadow: #992600;
    --button-text: #ffffff;
    --button-hover: #5d4e1e;
    --button-hover-shadow: #746125;
    --bg-icon: #ebf3ff;
    --bg-color: #ebf3ff;
}

.slick-slider {
    height: 100% !important;
}
.slick-list {
    height: inherit !important;
}
.slick-track {
    display: flex !important;
    /* gap: 30px !important; */
    position: relative !important;
}
.slick-slide,
.slick-active,
.slick-current {
    height: inherit !important;
}
.slick-slide > div {
    height: 100% !important;
}
/* .slick-prev,
.slick-next {
    z-index: 9999 !important;
    padding: 20px !important;
    border: 1px solid #000 !important;
    background-color: #fff !important;
    border-radius: 50% !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    height: 30px !important;
    width: 30px !important;
    position: relative;
}
.slick-prev::before{
    content: "ᚲ" !important;
    font-size: 70px !important;
    color: #000 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
} */
.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}
.no-scrollbar {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.unselectable {
    -webkit-user-select: none; /* For WebKit browsers (Safari, Chrome) */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For Internet Explorer/Edge */
    user-select: none; /* Standard syntax */
}

@keyframes typing {
    0%,
    25% {
        content: '';
    }
    26%,
    50% {
        content: '.';
    }
    51%,
    75% {
        content: '..';
    }
    76%,
    100% {
        content: '...';
    }
}

.typing-dots::after {
    content: '0';
    animation: typing 2s infinite;
}

.card-container {
    perspective: 1000px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.card-container.flipped .card {
    transform: rotateY(180deg);
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.back,
.rotateY180 {
    transform: rotateY(180deg);
}
