html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Leitura Display';
    src: url(../fonts/LeituraDisplayRoman.otf);
}

.font-Arial {
    font-family: 'Arial';
}

.font-Leitura {
    font-family: 'Leitura Display';
}

input[type='checkbox']:checked {
    background: #36998e;
}

.rheostat {
    position: relative;
    overflow: visible;
}
@media (min-width: 1128px) {
    .autoAdjustVerticalPosition {
        top: 12px;
    }
}
.DefaultProgressBar__vertical {
    width: 24px;
    height: 100%;
}
.DefaultProgressBar_progressBar {
    background-color: #e9e9e9;
    position: absolute;
    height: 4px;
    cursor: pointer;
}
.DefaultProgressBar_progressBar__vertical {
    height: 100%;
    width: 24px;
}
.DefaultProgressBar_background__vertical {
    height: 100%;
    top: 0px;
    width: 15px;
}
.DefaultProgressBar_background__horizontal {
    top: 0px;
}
.DefaultHandle_handle {
    background-image: url(../images/LocationIcon.svg) !important;
    width: 36px;
    height: 36px;
    outline: none;
    z-index: 2;
    margin-inline-start: -12px;
}

.DefaultHandle_handle[data-handle-key='1'] {
    display: none;
}

.DefaultHandle_handle:focus {
    box-shadow: #abc4e8 0 0 1px 1px;
}
.DefaultHandle_handle:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #dadfe8;
}
.DefaultHandle_handle:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #dadfe8;
}
.DefaultHandle_handle__horizontal {
    margin-left: -16px;
    top: -32px;
}

.DefaultHandle_handle__vertical {
    margin-top: -12px;
    left: -10px;
}
.DefaultHandle_handle__vertical:before {
    top: 10px;
}
.DefaultHandle_handle__vertical:after {
    top: 13px;
    left: 8px;
    height: 1px;
    width: 10px;
}
.DefaultHandle_handle__disabled {
    border-color: #dbdbdb;
}
.DefaultBackground {
    background-color: lightgrey;
    height: 15px;
    width: 100%;
}
.DefaultBackground_background__horizontal {
    height: 4px;
    cursor: pointer;
    background: #c7c7c7;
    width: 100%;
}
.DefaultBackground_background__vertical {
    width: 15px;
    top: 0px;
    height: 100%;
}

.handleContainer {
    height: 15px;
    top: -2px;
    left: -2px;
    bottom: 4px;
    width: 100%;
    position: absolute;
}

.dropdown-button-price {
    padding-top: 70px;
}

.slick-slide {
    padding: 0px 0px !important;
}

.slick-prev {
    z-index: 10;
}

.slick-prev::before {
    content: none;
    color: '#000' !important;
}

.slick-next {
    top: 0px;
    z-index: 10;
    height: 100%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.slick-next:before {
    content: none;
    color: '#000' !important;
}

@media (max-width: 1320px) {
    .slick-prev {
        width: 25px;
    }

    .slick-next {
        width: 25px;
    }
}

@media (max-width: 1024px) {
    .slick-prev {
        width: 20px;
    }

    .slick-next {
        width: 20px;
    }
}

@media (max-width: 768px) {
    .slick-prev {
        width: 10px;
    }

    .slick-next {
        width: 10px;
    }
}

.middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0%;
}

.middle-without-hover {
    position: absolute;
}

.container-wrapper {
    position: relative;
}

.image-covers {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
}

.container-wrapper:hover .image-covers {
    opacity: 0.3;
}

.container-wrapper:hover .middle {
    opacity: 1;
}

.custom-width {
    width: calc(50% - 15px);
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
    // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.is-valid {
    background-image: url(../images/checkmark.png);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.2rem;
    padding-right: 2.25rem;
}

.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid var(--theme-main);
    border-radius: 50%;
    width: 56px;
    height: 56px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bouncing-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.arrow {
    width: 2rem;
    height: 2rem;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}
